import { Box, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';

const DonwloadApp = () => {
  return (
    <Box p={10} mb={5} bg="rgba(15, 97, 214, 0.1)">
      <Flex
        flexDir={['column-reverse', 'row']}
        maxW="1472px"
        mx="auto"
        alignItems="center"
        justify="space-around"
      >
        <Stack
          align="center"
          maxW={['full', '40%']}
          gap={[2, 5]}
          textAlign="center"
        >
          <Heading
            color="#0F61D6"
            fontSize={['3xl', '5xl']}
            lineHeight={['initial', '3xl']}
          >
            Download the <br /> PicknFix Mobile App
          </Heading>
          <Text>
            For quick access to device protection and other activities in the
            app, Anytime you need to.
          </Text>
          <Flex gap={[2, 6]}>
            <Link href={process.env.NEXT_PUBLIC_GOOGLE_PLAY_LINK || ''}>
              <Image alt="google play" src="/images/google-play.svg" />
            </Link>
            <Link href={process.env.NEXT_PUBLIC_APPLE_STORE_LINK || ''}>
              <Image alt="apple store" src="/images/appstore.svg" />
            </Link>
          </Flex>
        </Stack>
        <Box>
          <Image alt="apple store" src="/images/download-app.svg" />
        </Box>
      </Flex>
    </Box>
  );
};

export default DonwloadApp;
